import React from "react";
import Layout from "../../components/layout/layout";
// import QRpaynow from "../../images/paynow.png";

import SEO from "../../components/seo";

const Donate = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Donate to SWA"
          description="Help us improve the lives
                of our beneficiaries as well as contribute to our charity
                efforts to enhance the welfare of the underprivileged."
        />
        <section className="">
          <div className="max-w-2xl px-4 mx-auto py-14 sm:px-6 lg:max-w-4xl lg:px-8">
            <div className="max-w-3xl">
              <h1 id="features-heading" className="font-medium text-gray-600">
                Donate
              </h1>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-swa-1 sm:text-4xl">
                Give a ray of hope
              </p>
              <p className="mt-4 text-xl leading-8 prose prose-lg text-gray-500">
                SWA welcomes donations and support from individuals and
                corporations. Your kind gesture will help us improve the lives
                of our beneficiaries as well as contribute to our charity
                efforts to enhance the welfare of the underprivileged. On behalf
                of SWA, we would like to thank you for your heart and
                generosity.
              </p>
            </div>

            <div className="grid items-start grid-cols-1 mt-11 gap-y-16 gap-x-6 sm:mt-16 md:grid-cols-3 ">
              <div className="flex flex-col-reverse">
                <div className="mt-6">
                  <h3 className="text-base font-bold text-swa-2">Cheque</h3>
                  <p className="mt-2 text-lg text-gray-500">
                    For cash donations, kindly make out crossed cheques to “SWA”
                    and mail them to the following address:
                  </p>
                  <p className="mt-2 text-lg text-gray-500">
                    Payable to:
                    <br />
                    <span className="font-bold">
                      Singapore Women’s Association
                    </span>
                  </p>
                  <p className="mt-2 text-lg text-gray-500">
                    Mail to:
                    <br />
                    <span className="font-bold">
                      Blk 409 Serangoon Central #01-303 Singapore 550409
                    </span>
                  </p>
                </div>
                <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-1 aspect-h-1">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636988711/participatePage/b6pxktbcremk0woujiqk.jpg"
                    alt="cheque"
                    className="object-cover object-center"
                  />
                </div>
              </div>
              <div className="flex flex-col-reverse">
                <div className="mt-6">
                  <h3 className="text-base font-bold text-swa-2">
                    Credit Card/NETS
                  </h3>
                  <p className="mt-2 text-lg text-gray-500">
                    Please visit
                    <a
                      href="https://www.giving.sg/singapore-women-s-association"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="font-bold text-swa-3"> Giving.sg</span>
                    </a>
                    if you wish to make online donations with credit card or
                    NETS
                    <br />
                    <br />
                    Giving.sg is created and powered by the National Volunteer &
                    Philanthropy Centre (NVPC). NVPC is an independent,
                    not-for-profit organisation that advocates giving in
                    Singapore.
                  </p>
                </div>
                <div className="overflow-hidden border border-gray-500 rounded-lg aspect-w-1 aspect-h-1">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636987848/participatePage/bsaky8hpunscakn4obja.png"
                    alt="Credit Card"
                    className="object-contain object-center"
                  />
                </div>
              </div>
              <div className="flex flex-col-reverse">
                <div className="mt-6">
                  <h3 className="text-base font-bold text-swa-2">PayNow</h3>
                  <p className="mt-2 text-lg text-gray-500">
                    For small donations up to S$1000, you may transfer via
                    PayNow using the QR code or UEN number{" "}
                    <span className="font-bold">S54SS0010L</span>.
                  </p>
                </div>
                <div className="overflow-hidden border border-gray-500 rounded-lg aspect-w-1 aspect-h-1">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636987848/participatePage/birqwtvua1wx64balsfs.jpg"
                    alt="PayNow"
                    className="object-cover object-center"
                  />
                </div>
              </div>
            </div>

            <div className="max-w-3xl mt-14">
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-center text-swa-1 sm:text-4xl">
                #givesafely initiative
              </p>
              <p className="mt-4 text-xl leading-8 prose prose-lg text-center text-gray-500">
                We are proud to support #givesafely initiative to create a safe
                space for you to donate!
              </p>
              <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-1 aspect-h-1">
                <img
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1675402151/participatePage/zv6j5fikr8jtiqg7xvso.webp"
                  alt="give safely"
                  className="object-cover object-center"
                />
              </div>
              <div>
                {/* <p className="mt-1 mt-4 text-xl leading-8 prose prose-lg text-gray-500">
                  We are proud to support #givesafely initiative to create a
                  safe space for you to donate!
                </p> */}
                <div className="max-w-sm mx-auto sm:flex sm:max-w-none sm:justify-center">
                  <div className="mt-3 sm:mx-auto">
                    <a
                      href="https://www.charities.gov.sg/Pages/Fund-Raising/Safer-Giving.aspx"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center px-4 py-3 text-base font-bold text-red-700 bg-pink-200 border border-transparent rounded-md shadow-sm hover:bg-indigo-50 sm:px-8"
                    >
                      Find out more at www.charities.gov.sg
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Donate;
